export enum ScannersApi {
  expoTools = 'ExpotoolsExpoleads',
  expoToolsEncrypted = 'ExpotoolsExpoleadsEncrypted',
  compuSystem = 'CompuSystemLeadApi',
  showValue = 'ShowValueSVCapture',
  maritGlobal = 'MaritzGlobalEvents',
  actionRegistration = 'ActionRegistration',
  metaData = 'MetaData',
  xPressLeads = 'XPressLeads',
  interaticaScanner = 'InteraticaScanner',
}
