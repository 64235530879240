<router-outlet></router-outlet>
<app-touchless-qrs></app-touchless-qrs>
<app-time-out-popup></app-time-out-popup>
<app-generic-popup></app-generic-popup>
<!-- TODO move chatbot to a component -->
<div *ngIf="chatbot.status > CHATBOT_STATUS.deActivated" class="position-absolute chatbot-z-index m-0 p-0" [ngStyle]="{
  width: chatbot.iframe.width + 'px',
  height: chatbot.iframe.height + 'px',
  top: chatbot.iframe.top,
  left: chatbot.iframe.left,
  bottom: chatbot.iframe.bottom,
  right: chatbot.iframe.right
}" [ngClass]="{
  'bkg-color-black-transparent': chatbot.isVisible,
  'w-100': chatbot.isVisible,
  'h-100': chatbot.isVisible,
  'd-flex': chatbot.isVisible,
  'justify-content-center': chatbot.isVisible,
  'align-items-center': chatbot.isVisible
}">
  <iframe #chatbotIframe [src]="CHAT_URL" [width]="chatbot.iframe.width" [height]="chatbot.iframe.height" class="border-0" (load)="onChatbotIframeLoaded()"></iframe>
</div>
<!-- TODO move int-scanner iframe to a component -->
<div *ngIf="isInteraticaScannerConfig && ['open', 'listening'].includes(intScanner.currentStatus)" class="position-absolute m-0 p-0 bkg-color-black-transparent w-100 h-100 d-flex justify-content-center align-items-center top-0 left-0 int-scanner-idx">
  <iframe [src]="INT_SCANNER_URL" width="95%" height="95%" class="border-0" (load)="intScanner.notifyScannerIframeLoaded()" allow="camera"></iframe>
  <button type="button" class="btn btn-primary border-0 bg-warning position-absolute top-0 right-0 text-black m-4 x-large" (click)="intScanner.cancel()"><b>CANCEL</b></button>
</div>
