import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
/**
 * note: this services was implement with idea to inly be used in the interatica scanner demo version
 * an improved version should be implemented
 */
@Injectable({ providedIn: 'root' })
export class InteraticaScannerDemoService {
  public readonly SCAN_URL = 'https://webcamtest.cleverlit.com';

  private status: BehaviorSubject<'none' | 'open' | 'listening' | 'done'> = new BehaviorSubject('none');
  public get onStatusChanged() {
    return this.status.asObservable();
  }
  public get currentStatus() {
    return this.status.value;
  }

  public readonly notifyScannerIframeLoaded: () => void;

  private data: {
    email?: string,
  } = {};
  public get collectedData() {
    return Object.freeze({ ...this.data });
  }

  constructor(
    private readonly logger: NGXLogger,
  ) {
    this.notifyScannerIframeLoaded = this.onScannerIframeLoaded.bind(this);
  }

  public start() {
    this.status.next('open');
  }

  public cancel() {
    this.status.next('none');
  }

  private onScannerIframeLoaded() {
    if (this.currentStatus === 'listening') { return; }
    this.setUpListener();
  }

  private setUpListener() {
    window.addEventListener(
      'message',
      this.onReceivedMsg.bind(this),
      { once: true },
    );
    this.logger.log(`[InteraticaScannerDemoService] Waiting post-msg from ${this.SCAN_URL}...`);
    this.status.next('listening');
  }

  private onReceivedMsg(event: MessageEvent) {
    // TODO parse and validate received msg
    if (!event.data
      || typeof (event.data) !== 'object'
      || !event.data?.email
      || typeof (event.data?.email) !== 'string'
      || !(event.data.email as string).trim().length) {
      this.logger.error(`The received data from ${this.SCAN_URL} this incorrect`, event?.data);
      return;
    }
    this.logger.info(`Received post-msg from ${event.origin}...`, event.data);
    this.data.email = event.data.email;
    this.status.next('done');
  }
}
